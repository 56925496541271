@import '../base/bootstrap-extended/include';
@import '../base/components/include';

/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


//campanhas


.dark-layout {
  .tabela-card
  {
    border: 1px solid #3c4256 !important;
  }
  .value-data
  {
    color: white;
  }
  .bg-nd
  {
    background-color: #2d3548;
  }
  .bg-cardInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #ececec;
    background-color: #f5f5f5;
    padding: 10px;
    padding-right: 20px;
    border-radius: 10px;
  }

  .bg-success1 {
    padding: 10px;
    border-radius: 50px;
    background-color: rgba(72, 211, 27, 0.13);
  }

  .icon-success {
    color: #1b9f49;
  }

  .bg-nom {
    padding: 10px;
    border-radius: 50px;
    background-color: rgba(255, 206, 143, 0.35);
  }

  .icon-nom {
    color: #b96a39;
  }

  .bg-rest {
    padding: 10px;
    border-radius: 50px;
    background-color: rgba(255, 206, 143, 0.35);
  }

  .icon-rest {
    color: #b96a39;
  }



  .posiDiv {
    margin-top: -110px;
    margin-left: -30px;
    position: absolute;
  }
  .dyGtOs {
    width: 100%;
    box-sizing: border-box;
    color: rgba(0,0,0,0.87);
    background-color: transparent !important;
  }
  .rec-add {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 230px;
  }

  .rec-add-comp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 170px;
  }

  .cupomPop{
    color: white;
  }

  .addLote:hover {
    cursor: pointer;
    transition: 0.2s;
    background-color: rgba(72, 243, 53, 0.1);
  }

  .status-lote {

    margin-top: 10px;
    margin-bottom: 10px;
  }

  h5 {
    margin: 0;
  }

  .custombtnRound {
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    width: 35px;
  }

  //login



  .auth-wrapper .auth-bg {
    background: #181818;
  }

  .botaoGo {
    font-family: Poppins, sans-serif;
    background: linear-gradient(347deg, #14265c, #23def4);
    border: none !important;
    height: 45px;
  }

  .bg-logo {
    background-image: url("https://i.imgur.com/gpishHd.jpg");
    background-size: cover;
    height: 100vh;
  }

  @media (min-width: 600px) {
    .logoIni {
      display: none !important;
    }
  }

  @media (min-width: 1500px) {
    .imagemIlu {
      width: 600px !important;
      height: 600px !important;
    }
  }

  .imagemIlu {
    margin-top: 80px;
    width: 450px;
    height: 450px;
  }

  //Inicio

  .bg-menu {
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
    border-radius: 10px;
    overflow: hidden;
  }

  .txt-menu {
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
  }

  .divisao {
    height: 1px;
    background-color: rgba(180, 180, 180, 0.11);
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(43 84 129 / 48%)  !important;
  }

  .text-info-data
  {
    color: white;
  }

  .color-wt {
    color: white;
  }
  .card-member-list {
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    border: solid 1px rgba(224, 224, 224, 0.18) !important;
    padding: 10px;
  }

  .card-member-list:hover {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.11) !important;
    margin-top: 5px;
    border: solid 1px rgba(224, 224, 224, 0.18) !important;
    padding: 10px;
  }

  .text-member
  {
    color: white;
    font-size: 13px;
  }

  .text-member-role
  {
    font-size: 11px;
    font-weight: 400;
    color: #a0aac9;
  }

  .fc-col-header-cell-cushion{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    color: white;
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .fc .fc-multimonth-daygrid {
    background: transparent !important;
  }

  .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid rgba(102, 110, 134, 0.37) !important;
  }

  .fc-multimonth-title
  {
    color: white;
    text-transform: capitalize;
    font-weight: 400 !important;
  }

  .fc .fc-multimonth {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-top: 1px solid rgba(102, 110, 134, 0.37) !important;
  }

  .fc-theme-standard .fc-scrollgrid {
    border-top: 1px solid #2c3141 !important;
    border-left: #21283c !important;
    border-bottom: none !important;
    border-right: #21283c !important;
  }

  .menu-selecionado {
    background: linear-gradient(144deg, rgba(39, 46, 81, 0.88), rgba(46, 54, 70, 0.97));
    //background: linear-gradient(149deg, rgba(63, 111, 255, 1) 0%, rgba(30, 16, 96, 1) 100%);
    padding: 15px 20px 15px 20px;
    color: white;
  }

  .menu-selecionado-no:hover {
    cursor: pointer;
    background-color: rgba(39, 44, 61, 0.8);
    padding: 15px 20px 15px 20px;
  }

  .menu-selecionado-no {
    color: #727272;
    padding: 15px 20px 15px 20px;
  }

  .bIng {
    text-align: center;
    margin-right: 10px;
    padding: 0 0 10px 0;
    font-weight: normal;
    background-color: transparent !important;
    color: black;
    border: solid 1px rgba(231, 231, 231, 0.12);
  }


  .bg-Ingn {
    font-size: 12px;
    color: white;
    padding: 10px;
    background: linear-gradient(144deg, rgba(18, 22, 40, 0.85), rgba(46, 54, 70, 0.8));
  }

  .nIng {
    color: white !important;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }

  .fIng {
    color: #36ff28;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }


  h5 {
    margin: 0
  }

  //Financeiro
  .card-retirada {

    font-weight: 600;
    font-size: 16px;
    color: black;
    margin-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    border: solid 1px #d3d3d3;
  }

  .menu-title
  {
    font-size: 12px !important;
  }

  //componente dados evento

  .titulo-evento {
    font-size: 19px;
    color: #ffffff !important;
    font-weight: 600;
    font-family: 'Poppins SemiBold', sans-serif;
  }

  .content-sub
  {
    color: #9ea8c5 !important;
    font-weight: 400;
    font-size: 12px !important;
  }

  .titulo-card {
    color: #ffffff;
    border-left: solid 3px #1ab7ea;
    padding-left: 7px;
  }

  .desc-info-2
  {
    font-family: Poppins, sans-serif !important;
    font-size: 14px !important;
    font-weight: 600;
    color: #ffffff;
  }

  .titulo-card-gerar {
    border-left: solid 3px #17ce06;
    padding-left: 7px;
  }

  .titulo-card-reenviar {
    border-left: solid 3px #d6d300;
    padding-left: 7px;
  }

  .titulo-card-cancelar {
    border-left: solid 3px #b60000;
    padding-left: 7px;
  }

  .titulo-card-dark {
    border-left: solid 3px #ffffff;
    padding-left: 7px;
  }



  //=============================

  .barraSuges {
    border: solid 1px #e5e5e5;
    padding: 5px;
    font-size: 13px;
  }

  h3 {
    margin: 0;
  }

  //Ingressos
  .estilo-quadro {
    border: solid 1px #dadada;
    background: #f1f1f1;
    padding: 8px 3px 8px 3px;
  }

  .card-ing-g {
    border: solid 1px #dadada;
    padding: 10px;
  }

  .tab-select {
    height: 38px;
    margin-bottom: -6px;
    padding: 8px 35px 8px 35px;
    border: solid 1px #d9d9d9;
    border-bottom: none;
    background-color: #ffffff;
  }

  .borda-card {
    margin-top: -1px;
    border-radius: 0 0 0 0 !important;
    border: solid 1px #d9d9d9;
  }

  .tab-select-no:hover {
    cursor: pointer;
    padding: 8px 35px 8px 35px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
  }

  .tab-select-no {
    cursor: pointer;
    padding: 8px 35px 8px 35px;
    border: solid 1px #d9d9d9;
    background-color: #ececec;
  }

  //=========


  h4 {
    margin: 0;
  }


  p {
    margin: 0;
  }

  .grafico-boladao {
    border: solid 1px #e7e7e7;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardIng {
    padding: 15px;
    border-radius: 10px;
    border: solid 1px #eaeaea;
  }

  .show>.dropdown-menu {
    transform: scale(1, 1) !important;
  }

  //menu
  //menu





  .main-menu.menu-dark .navigation>li>a {
    padding-left: 15px;
    padding-bottom: 13px;
    padding-top: 13px;
  }



  //compradores


  //Leads

  .codcad {
    background-color: transparent !important;
    border: solid 1px rgba(224, 220, 220, 0.13) !important;
    margin-right: 5px;
    border-radius: 3px;
    margin-bottom: 5px;
    color: #eeeeee !important;
  }

  .btn-back {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 50px;
    border: solid 2px #3cffea;
  }

  .menu-content {
    background-color: transparent !important;
  }
  [dir] .dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
    background-color: #252525;
  }

  h6 {
    margin: 0;
  }

  .select__multi-value__label {
    background-color: #3a3a3a;
  }

  .select__multi-value .select__multi-value__remove svg {
    background-color: #c95d5d;
  }

  .apexcharts-datalabel-value
  {
    color: white !important;
    fill: white !important;
  }

  img.img-whatsapp-envios {
    width: 40px !important;
    margin-top: 15px;
  }

  .main-menu ul.navigation-main > li:first-child {
    margin-top: 0 !important;
  }

  .spacemenutop
  {
    border-top: solid !important;
    border-width: 1px !important;
    border-color: #1e253a !important;
  }

  .navbar-sticky .header-navbar.navbar-shadow {
    box-shadow: none !important;
  }

  .spacemenu
  {
    border-bottom: solid !important;
    border-width: 1px !important;
    border-color: #1e253a !important;
  }

  .main-menu.menu-dark .navigation > li > a {
    margin: 0 0 !important;
    padding-left: 30px;
  }

  .main-menu {
    background-color: #0d0e11 !important;
    border-right: solid;
    border-width: 2px !important;
    border-color: #1e253a !important;
  }
  .modal-content
  {
    border: solid 1px #474f6759 !important;
    background-color: rgba(21, 24, 31, 0.87) !important;
    box-shadow: 0 8px 32px 0 rgb(11 12 17 / 44%) !important;
    backdrop-filter: blur( 4px ) !important;
    -webkit-backdrop-filter: blur( 4px ) !important;
    border-radius: 10px;
  }

  .btn-outline-success
  {
    border-radius: 4px !important;
    font-weight: 500 !important;
    border: 1px solid #28c76f6b !important;
  }
  .btn-outline-danger
  {
    border-radius: 4px !important;
    font-weight: 500 !important;
    border: 1px solid rgba(234, 84, 84, 0.65) !important;
  }

  .modal-body
  {
    background-color: transparent !important;
  }

  .form-control {
    border-color: #ffffff2e !important;
    background-color: #10151e !important;
    border-radius: 5px !important;
  }

  .modal-header
  {
    background-color: transparent !important;
    border-bottom: solid 2px #33424f !important;
  }

  .modal-footer {
    background-color: transparent !important;
  }

  [dir] .dark-layout .modal .modal-header, [dir] .dark-layout .modal .modal-header[class*=bg-] {
    background-color: #3d3d3d !important;
  }

  .dark-layout .modal .modal-header, [dir] .dark-layout .modal .modal-header[class*=bg-] {
    background-color: #1c1c1c !important;
  }

  .color-icon
  {
    color: #23def4 !important;
  }
  .text-color {
    color: #ffffff;
  }


  .logo-pesa {
    paint-order: fill;
    stroke-dashoffset: 17px;
    fill: rgb(254, 200, 32);
    stroke: rgb(254, 200, 32);
  }


  .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
    background: #fec820 !important;
    color: black !important;
    box-shadow: none;
  }

  .fontetxt-pesa {
    font-family: 'Montserrat ExtraBold', serif;
    color: white !important;
    font-weight: 600 !important;
    font-size: 22px !important;
  }

  .main-menu.menu-dark .navigation li a {
    color: #eaeaea;
    line-height: 1.45;
  }

  .text-primary {
    --bs-text-opacity: 1;
    color: white !important;
  }

  .estilo-ico-expande {
    .text-primary {
      --bs-text-opacity: 1;
      color: white !important;
    }

    color: white !important;
  }

  @media (max-width: 600px) {
    .oculta-mobile {
      display: none;
    }

    .main-menu .navbar-header .navbar-brand {
      margin-top: 0.5rem !important;
    }

    .brand-text {
      margin-left: -15px !important;
    }

    .main-menu .navbar-header .navbar-brand .brand-text {
      padding-left: 0;
      animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    }
  }

  .estilotg {
    position: absolute;
    right: 0;
    top: 32px;
  }


  [dir] .main-menu.menu-dark .navigation>li.open:not(.menu-item-closing)>a,
  [dir] .main-menu.menu-dark .navigation>li.sidebar-group-active>a {
    color: white;
    border-radius: 0;
  }

  .main-menu.menu-dark .navigation>li ul .active>a {
    color: black;
  }


  .main-menu.menu-dark.navigation>li ul .active {
    color: #ffffff !important;
    background: #161D31FF !important;
  }

  [dir=ltr] .vertical-layout .main-menu .navigation a.active {
    background: #161D31FF !important;
  }

  [dir] .main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a, [dir] .main-menu.menu-dark .navigation > li.sidebar-group-active > a {
    background: #161D31FF !important;
    color: white;
    border-radius: 0;
  }

  [dir] .vertical-layout .main-menu .navigation a.active {
    box-shadow: none !important;
    border-radius: 0;
  }

  .dark-layout .main-menu-content .navigation-main .sidebar-group-active .menu-content
  {
    background-color: transparent;
    padding: 10px 0 20px 0 !important;
  }

  .main-menu.menu-dark .navigation>li.active>a {
    color: #ffffff;
    background: #161D31FF !important;
  }
  .rounded-end-0
  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .spacemenu
  {
    font-size: 12px !important;
  }


  .notion {
    color: white !important;
  }
  //.main-menu .navbar-header {
  //  padding: 0 10px 10px!important;
  //}

  .main-menu.menu-dark .navigation {
    background: transparent !important;
  }


  [dir] .main-menu.menu-dark .navigation>li ul .active {
    box-shadow: none;
    border-radius: 4px;
  }


  .btnGoIng {
    background: linear-gradient(347deg, #14265c, #23def4) !important;
    color: white;
    border: none !important;
  }





















  .svgFunnelContainer-0-0-8 {
    padding-top: 20px !important;
  }

  .svgFunnelLabelValue-0-0-7 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;

  }

  .svgFunnelLabel-0-0-2 {
    padding-top: 20px !important;
  }

  .icone-top:hover
  {
    transition: 0.5s;
    cursor: pointer;
    color: #ffffff !important;
  }
  .table {
    --bs-table-color-type: initial;
    --bs-table-bg-type: initial;
    --bs-table-color-state: initial;
    --bs-table-bg-state: initial;
    --bs-table-color: #6e6b7b;
    --bs-table-bg: #232939 !important;
    --bs-table-border-color: #ebe9f1;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #6e6b7b;
    --bs-table-striped-bg: #fafafc;
    --bs-table-active-color: #6e6b7b;
    --bs-table-active-bg: rgba(34,41,47,0.1);
    --bs-table-hover-color: #6e6b7b;
    --bs-table-hover-bg: #f6f6f9;
    width: 100%;
    vertical-align: middle;
  }

  .cardEvInfo
  {
    border: solid 1px rgba(217, 217, 217, 0.12) !important;
  }

  .name-value{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
  }

  .cardEv {
    overflow: hidden;
    cursor: pointer;
    border: solid 1px rgba(217, 217, 217, 0.27);
    border-radius: 10px;
  }

  .input-copy
  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .btn-email-sent {
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    width: 40px; /* Garantir proporções */
    height: 40px; /* Garantir proporções */
    border-radius: 50% !important; /* Torna o botão redondo */
    display: flex; /* Centraliza o ícone */
    align-items: center; /* Centraliza o ícone */
    justify-content: center; /* Centraliza o ícone */
  }

  .btn-email-not-sent {
    background-color: transparent !important;
    color: black !important;
    border: 1px solid #ccc !important;
    width: 40px; /* Garantir proporções */
    height: 40px; /* Garantir proporções */
    border-radius: 50% !important; /* Torna o botão redondo */
    display: flex; /* Centraliza o ícone */
    align-items: center; /* Centraliza o ícone */
    justify-content: center; /* Centraliza o ícone */
  }

  .btn-round
  {
    border-radius: 50px !important;
  }

  .cardEv>div {
    min-height: 60px;
  }

  //RESUMO

  .titulo-info {}

  .test {
    display: inline-block;
    margin: 2em auto;
    border: 2px solid #000;
    font-size: 2em;
  }

  .result-info {
    font-weight: 600;
    color: #57ff4b;
  }


  .btn-cuponPop {
    border: solid 1px rgba(224, 224, 224, 0.15) !important;
    color: #ffffff !important;
    background-color: transparent !important;
    font-size: 12px;
  }
  .btn-back-2 {
    height: 40px !important;
    background-color: transparent !important;
    border: solid 1px rgba(224, 224, 224, 0.13) !important;
    color: #ffffff !important;
    font-size: 12px;
  }

  .apexcharts-text.apexcharts-xaxis-label
  {
    color: white !important;
    fill: rgba(255, 255, 255, 0.62) !important;

  }

  .apexcharts-legend-text
  {
    color: white !important;
  }

  .apexcharts-text.apexcharts-yaxis-label{
    fill: rgba(255, 255, 255, 0.62) !important;
  }

  .btn-cuponPop:hover {
    box-shadow: 0 8px 25px -8px #82868b;
    background-color: transparent !important;
    border: solid 1px rgba(224, 224, 224, 0.15) !important;
    color: #ffffff !important;
    font-size: 12px;
  }

  .btn-cuponPop:focus {
    background-color: transparent !important;
    border: solid 1px rgba(224, 224, 224, 0.15) !important;
    color: #ffffff !important;
    font-size: 12px;
  }


  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #18237e !important;
  }


  .dimiInnertd {
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: 12px;
    text-transform: lowercase;
  }


  .newInputG {
    padding: 0.471rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
  }


  .cardHomeEvents {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 65px;
    background-color: #000000ab !important;
    border-top: 10px solid #000000ab !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 15px 15px 0 0 !important;

  }

  // cardHomeEvents > div > h5
  .cardHomeEvents>div {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin: 5px;
  }


  .dark-layout .bs-stepper {
    background: transparent !important;
  }

  .card-info-resume
  {
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61)) !important;
    padding: 20px;
    border-radius: 7px;
    border: 1px solid #2c3141;
  }

  .ficon
  {
    color: white !important;
  }

  .card-info-nomg
  {
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
    padding: 20px;
    border-radius: 30px;
    border: 1px solid #2c3141;
  }

  .card-info-date-selected
  {
    background: linear-gradient(144deg, rgb(31 43 55 / 46%), rgb(0 214 255 / 63%));
    border-radius: 15px;
    border: none;
  }

  .date-filter-txt
  {
    color: white;
  }

  .card-info-date:hover
  {
    background: linear-gradient(144deg, rgba(54, 64, 112, 0.45), rgba(71, 90, 126, 0.68));
    border-radius: 30px;
    border: 1px solid #2c3141;
  }

  .text-graph
  {
    color: white;
    font-size: 12px !important;
  }

  .date-filter
  {
    margin-top: 10px !important;
    font-size: 12px;
    color: white;
    font-weight: 400;
  }

  .card-info-date
  {
    cursor: pointer;
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
    border-radius: 15px;
    border: 1px solid #2c3141;
  }

  .card-info
  {
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #2c3141;
  }
  .ticket-style {
    margin-bottom: 20px;
    border: 1px solid #2c3141;
    padding: 20px;
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
  }

  .card-info-3
  {
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
    border-radius: 0;
    border: 1px solid #2c3141;
  }

  .card-info-2
  {
    background: linear-gradient(144deg,rgba(39,46,81,.2),rgba(46,54,70,.61));
    padding: 10px;
    border-radius: 30px;
    border: 1px solid #2c3141;
  }

  .swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    grid-template-columns: minmax(0,100%);
    width: 32em;
    max-width: 100%;
    padding: 0 0 1.25em;
    border-radius: 5px;
    background: linear-gradient(144deg, rgba(25, 30, 51, 0.71), rgba(24, 36, 59, 0.9));
    border: 1px solid rgba(44, 49, 65, 0.4);
    color: #545454;
    font-family: inherit;
    font-size: 1rem;
  }

  .swal2-html-container
  {
    color: white !important;
  }

  .newForm
  {
    background-color: transparent !important;
  }

  .css-1pahdxg-control
  {
    color: white !important;
    border: solid 1px rgba(255, 255, 255, 0.62);
    background-color: transparent !important;
  }
  .css-1s2u09g-control
  {
    color: white !important;
    border: solid 1px rgba(255, 255, 255, 0.62);
    background-color: transparent !important;
  }


  .css-1pndypt-Input
  {
    color: white !important;
  }

  .fc .fc-multimonth-daygrid {
    background: transparent !important;
  }

  .css-qc6sy-singleValue {
    color: white !important;
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }

  .swal2-confirm.swal2-styled
  {
    margin-right: 10px;
  }

  .bg-transparent.btn- {
    svg {
      color: $theme-dark-body-color;
    }
  }

  // ** App Invoice
  .invoice-edit,
  .invoice-add {
    .invoice-edit-input-group .input-group-text {
      border-right-color: $theme-dark-card-bg !important;
    }
  }

  .invoice-print {
    .table {
      th {
        background-color: transparent !important;
      }
    }
  }

  // Autocomplete Navbar
  .autocomplete-container {
    .suggestions-list {
      .suggestion-item {
        &.suggestion-title-wrapper h6 {
          color: $theme-dark-text-muted-color;
        }
      }
    }
  }

  // Ecommerce Application
  .ecommerce-application .list-view .ecommerce-card .card-body {
    border-right-color: $theme-dark-border-color;
  }

  // Chat Application
  .chat-application {
    .more-options-dropdown .btn-icon svg {
      color: $theme-dark-body-color;
    }
  }

  // Pagination Break
  .pagination {
    .break {
      background-color: $theme-dark-pagination-bg;
      a {
        color: $theme-dark-body-color;
      }
    }
  }

  // Invoice data table
  .invoice-list-wrapper header {
    background-color: $theme-dark-card-bg;
  }

  // BlockUI
  .block-ui-container {
    .block-ui-overlay {
      background: $theme-dark-body-bg;
    }
    .block-ui-message {
      color: $theme-dark-body-color;
    }
  }

  // Pricing
  .pricing-card .card.popular {
    border-color: $primary !important;
  }




  .fc-theme-standard .fc-list-day-cushion {
    background-color: #2b394b !important;
    color: white !important;
    font-weight: 400 !important;
  }

  .fc-theme-standard .fc-list {
    border-radius: 10px !important;
    border-right: 1px solid rgba(102, 110, 134, 0.37) !important;
  }


  .fc-theme-standard .fc-list {
    border: 1px solid transparent !important;
  }



  .fc .fc-list-event:hover td {
    background-color: transparent !important;
  }

  // Form Control
  .form-control {
    background-color: transparent;
  }
  input.form-control.is-valid:not(:focus) {
    border-color: $success;
  }

  // Apex Charts
  .apexcharts-gridlines-vertical line {
    stroke: $theme-dark-border-color;
  }

  .horizontal-layout {
    &.navbar-static .header-navbar.navbar-scrolled {
      background-color: #0d0e11 !important;
    }
  }

  .header-navbar {
    background-color: #0d0e11 !important;
  }

  .card-not{
    background-color: #1e253a !important;
    border: solid 2px rgba(255, 255, 255, 0.05);
  }

  // Horizontal Menu Scrollbar
  .horizontal-menu {
    .horizontal-menu-wrapper {
      .navbar-horizontal {
        #main-menu-navigation {
          .dropdown-submenu {
            &.sidebar-group-active.active {
              .dropdown-toggle {
                color: $theme-dark-body-color !important;
              }
            }
          }
          .dropdown-item.active {
            background-color: $theme-dark-body-bg !important;
          }
          ul.dropdown-menu {
            &::-webkit-scrollbar-thumb {
              background: $theme-dark-body-bg;
            }

            &::-webkit-scrollbar-track {
              background: $theme-dark-border-color;
            }
          }
        }
      }
    }
  }

}


@media (max-width: 600px) {

  .btn-back-2 {
    height: 35px !important;
    background-color: transparent !important;
    border: solid 1px rgba(224, 224, 224, 0.13) !important;
    color: #ffffff !important;
    font-size: 10px !important;
  }

}

.modal-max-index{
  z-index: 9999999;
  width: 5000px !important;
}

.scroll-modal-portaria::-webkit-scrollbar {
  width: 8px !important;
  background: #fff !important;
  border-radius: 10px !important;
}

.scroll-modal-portaria::-webkit-scrollbar-thumb {
  background: rgb(159, 159, 159) !important;
  border-radius: 10px !important;
  height: 200px !important;
  border: 2px #ffffff solid !important;
}

.scroll-modal-portaria::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 10px !important;
}

.scroll-modal-portaria::-webkit-scrollbar-track:hover {
  background: #fff !important;
  border-radius: 10px !important;
}
