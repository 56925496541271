@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700&display=swap");

.pdfWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}

.bg-cardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #ececec;
  background-color: #f5f5f5;
  padding: 10px;
  padding-right: 20px;
  border-radius: 10px;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.bg-success1 {
  padding: 10px;
  border-radius: 50px;
  background-color: rgba(72, 211, 27, 0.13);
}

.icon-success {
  color: #1b9f49;
}

.bg-nom {
  padding: 10px;
  border-radius: 50px;
  background-color: rgba(255, 206, 143, 0.35);
}
.dark-layout .newForm {
  background-color: transparent !important;
  color: #4c4c4c !important;
}
.icon-nom {
  color: #b96a39;
}
.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
[dir] .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background: #000000;
  border-radius: 6px;
}
.bg-rest {
  padding: 10px;
  border-radius: 50px;
  background-color: rgba(255, 206, 143, 0.35);
}

.icon-rest {
  color: #b96a39;
}

.posiDiv2 {
  margin-top: 15px;
  margin-left: -10px;
  position: absolute;
}

.posiDiv {
  margin-top: -110px;
  margin-left: -30px;
  position: absolute;
}

@media (max-width: 600px) {
  .posiDiv {
    margin-left: -9px !important;
  }
}

.dyGtOs {
  width: 100%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent !important;
}

.rec-add {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 230px;
}

.btn-create {
  margin-top: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.rec-add-comp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 170px;
}

.status-lote {
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  margin: 0;
}

.custombtnRound {
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: center;
  width: 35px;
}

//login

.auth-wrapper .auth-bg {
  background-image: url("https://i.imgur.com/SFHh8TC.jpg");
  background-size: cover;
}

.botaoGo {
  font-family: Poppins, sans-serif;
  background: linear-gradient(347deg, #14265c, #23def4);
  border: none !important;
  height: 45px;
}

.bg-logo {
  background-image: url("https://i.imgur.com/gpishHd.jpg");
  background-size: cover;
  height: 100vh;
}

@media (min-width: 600px) {
  .logoIni {
    display: none !important;
  }
}

@media (min-width: 1500px) {
  .imagemIlu {
    width: 600px !important;
    height: 600px !important;
  }
}

.imagemIlu {
  margin-top: 80px;
  width: 450px;
  height: 450px;
}

//Inicio

.bg-menu {
  background: linear-gradient(
    144deg,
    rgba(39, 46, 81, 0.2),
    rgba(208, 212, 222, 0.61)
  );
  border-radius: 10px;
  overflow: hidden;
}

.txt-menu {
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.divisao {
  height: 1px;
  background-color: rgba(180, 180, 180, 0.11);
}

.menu-selecionado {
  background: linear-gradient(
    144deg,
    rgba(112, 112, 112, 0.88),
    rgba(132, 143, 162, 0.97)
  );
  //background: linear-gradient(149deg, rgba(63, 111, 255, 1) 0%, rgba(30, 16, 96, 1) 100%);
  padding: 15px 20px 15px 20px;
  color: #ffffff;
}

.menu-selecionado-no:hover {
  cursor: pointer;
  background-color: rgba(177, 182, 197, 0.8);
  padding: 15px 20px 15px 20px;
}

.menu-selecionado-no {
  color: #727272;
  padding: 15px 20px 15px 20px;
}

.bIng {
  text-align: center;
  margin-right: 10px;
  padding: 0 0 10px 0;
  font-weight: normal;
  background-color: transparent !important;
  color: black;
  border: solid 1px rgba(173, 173, 173, 0.58);
}

hr {
  color: #c1c0c4;
  opacity: 1;
}

.bg-Ingn {
  font-size: 12px;
  color: white;
  padding: 10px;
  background: linear-gradient(
    144deg,
    rgba(18, 22, 40, 0.85),
    rgba(46, 54, 70, 0.8)
  );
}

.nIng {
  color: #2f2f2f !important;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.fIng {
  color: #1faf14;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

h5 {
  margin: 0;
}

//Financeiro
.card-retirada {
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin-top: 6px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 1px #d3d3d3;
}

//componente dados evento

.titulo-evento {
  font-size: 22px;
  color: black;
  font-weight: 600;
  font-family: "Poppins SemiBold", sans-serif;
}

.titulo-card {
  color: #252525;
  border-left: solid 3px #1ab7ea;
  padding-left: 7px;
}

.titulo-card-gerar {
  border-left: solid 3px #17ce06;
  padding-left: 7px;
}

.titulo-card-reenviar {
  border-left: solid 3px #d6d300;
  padding-left: 7px;
}

.titulo-card-cancelar {
  border-left: solid 3px #b60000;
  padding-left: 7px;
}

.titulo-card-dark {
  border-left: solid 3px #161616;
  padding-left: 7px;
}

//=============================

.barraSuges {
  border: solid 1px #e5e5e5;
  padding: 5px;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  font-size: 13px;
}

h3 {
  margin: 0;
}

//Ingressos
.estilo-quadro {
  border: solid 1px #dadada;
  background: #f1f1f1;
  padding: 8px 3px 8px 3px;
}

.card-ing-g {
  border: solid 1px #dadada;
  padding: 10px;
}

.tab-select {
  height: 38px;
  margin-bottom: -6px;
  padding: 8px 35px 8px 35px;
  border: solid 1px #d9d9d9;
  border-bottom: none;
  background-color: #ffffff;
}

.borda-card {
  margin-top: -1px;
  border-radius: 0 0 0 0 !important;
  border: solid 1px #d9d9d9;
}

.tab-select-no:hover {
  cursor: pointer;
  padding: 8px 35px 8px 35px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
}

.tab-select-no {
  cursor: pointer;
  padding: 8px 35px 8px 35px;
  border: solid 1px #d9d9d9;
  background-color: #ececec;
}

//=========

h4 {
  margin: 0;
}

p {
  margin: 0;
}

.grafico-boladao {
  border: solid 1px #e7e7e7;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardIng {
  padding: 15px;
  border-radius: 10px;
  border: solid 1px #eaeaea;
}

.show > .dropdown-menu {
  transform: scale(1, 1) !important;
}

//menu
//menu

.main-menu.menu-light .navigation li a {
  color: #000000;
  line-height: 1.45;
}

.main-menu.menu-light .navigation li a {
  color: #000000;
  line-height: 1.45;
}
.main-menu {
  background-color: #0d0e11;
}

.nav-item.has-sub {
  // margin-bottom: 15px;
  color: white;
  border-bottom: solid;
  border-width: 1px;
}
.menu-dark .nav-item.has-sub {
  border-color: #1e253a;
}

.menu-light .nav-item.has-sub {
  border-color: #bdc5df;
}

[dir] .main-menu.menu-light .navigation {
  background: transparent !important;
}

//compradores

//Leads

.codcad {
  background-color: transparent !important;
  border: solid 1px #c9c9c9 !important;
  margin-right: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  color: black !important;
}

.btn-back {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 50px;
  border: solid 2px #494949;
}

h6 {
  margin: 0;
}

.select__multi-value__label {
  background-color: #3a3a3a;
}

.select__multi-value .select__multi-value__remove svg {
  background-color: #c95d5d;
}

img.img-whatsapp-envios {
  width: 40px !important;
  margin-top: 15px;
}

.logo-pesa {
  paint-order: fill;
  stroke-dashoffset: 17px;
  fill: rgb(254, 200, 32);
  stroke: rgb(254, 200, 32);
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .main-menu:not(.expanded)
  .navigation
  li.active
  a {
  background: #fec820 !important;
  color: black !important;
  box-shadow: none;
}

.fontetxt-pesa {
  font-family: "Montserrat ExtraBold", serif;
  color: white !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.main-menu.menu-dark .navigation li a {
  color: #eaeaea;
  line-height: 1.45;
}

.text-primary {
  --bs-text-opacity: 1;
  color: white !important;
}

.estilo-ico-expande {
  .text-primary {
    --bs-text-opacity: 1;
    color: white !important;
  }

  color: white !important;
}

.margin-title-mob {
  margin-bottom: 0;
  padding-top: 0;
}

.margin-mobile {
  margin: 0;
}

.text-mobile {
  font-size: small;
}

@media (max-width: 600px) {
  .main-menu .navbar-header .navbar-brand {
    margin-top: 0.5rem !important;
  }

  .brand-text {
    margin-left: -15px !important;
  }

  .main-menu .navbar-header .navbar-brand .brand-text {
    padding-left: 0;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
  }

  .color-mobile {
    margin: 0 0 5px 0;
  }

  .text-mobile {
    font-size: 14px;
  }

  .margin-title-mob {
    margin-bottom: -40px;
    padding-top: 5px;
  }
}

.estilotg {
  position: absolute;
  right: 0;
  top: 32px;
}

.navigation-main {
  padding-left: 20px;
  padding-right: 20px;
}

[dir] .main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a,
[dir] .main-menu.menu-dark .navigation > li.sidebar-group-active > a {
  color: white;
  border-radius: 0;
}

.main-menu.menu-dark .navigation > li ul .active > a {
  color: black;
}

.main-menu.menu-dark.navigation > li ul .active {
  color: #ffffff !important;
  background: linear-gradient(347deg, #14265c, #23def4);
}

[dir="ltr"] .vertical-layout .main-menu.menu-dark .navigation a.active {
  background: #161d31;
}

[dir] .vertical-layout .main-menu .navigation a.active {
  box-shadow: none !important;
  border-radius: 0;
}

.dark-layout
  .main-menu-content
  .navigation-main
  .sidebar-group-active.open
  .menu-content {
  background-color: transparent;
  padding: 10px 0 20px 0 !important;
}

.main-menu.menu-dark .navigation > li.active > a {
  color: #ffffff;
  background: #161d31ff !important;
}

//.main-menu .navbar-header {
//  padding: 0 10px 10px!important;
//}

.main-menu.menu-dark .navigation {
  background: transparent !important;
}

[dir] .main-menu.menu-dark .navigation > li ul .active {
  box-shadow: none;
  border-radius: 4px;
}

.btnGoIng {
  background: linear-gradient(347deg, #14265c, #23def4) !important;
  color: white;
  border: none !important;
}

.svgFunnelContainer-0-0-8 {
  padding-top: 20px !important;
}

.svgFunnelLabelValue-0-0-7 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.svgFunnelLabel-0-0-2 {
  padding-top: 20px !important;
}

.cardEv {
  overflow: hidden;
  cursor: pointer;
  border: solid 1px rgba(217, 217, 217, 0.27);
  border-radius: 10px;
}

.cardEv > div {
  min-height: 60px;
}

//RESUMO

.titulo-info {
}

.test {
  display: inline-block;
  margin: 2em auto;
  border: 2px solid #000;
  font-size: 2em;
}

.result-info {
  font-weight: 600;
  color: #37c52d;
}

.text-color {
  color: black;
}

.btn-cuponPop {
  border: solid 1px rgba(98, 98, 98, 0.33) !important;
  color: #1c1c1c !important;
  background-color: transparent !important;
  font-size: 12px;
}

.btn-reserve-maps {
  border: solid 1px rgba(220, 53, 69, 0.5) !important; /* Borda vermelha transparente */
  color: #ab454c !important; /* Texto branco */
  background-color: rgba(
    220,
    53,
    69,
    0.1
  ) !important; /* Fundo vermelho opaco */
  font-size: 12px;
  transition: background-color 0.3s ease; /* Suaviza a transição */
}

.btn-reserve-maps:hover {
  background-color: rgba(
    220,
    53,
    69,
    0.3
  ) !important; /* Fundo mais opaco ao passar o mouse */
  border-color: rgba(220, 53, 69, 0.7) !important; /* Borda mais visível */
}

.btn-back-2 {
  height: 40px !important;
  background-color: transparent !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: #2c2c2c !important;
  font-size: 12px;
}

.apexcharts-text.apexcharts-xaxis-label {
  color: #111111 !important;
  fill: rgba(38, 38, 38, 0.62) !important;
}

.apexcharts-legend-text {
  color: #1f1f1f !important;
}

.apexcharts-text.apexcharts-yaxis-label {
  fill: rgba(47, 47, 47, 0.62) !important;
}

.btn-cuponPop:hover {
  box-shadow: 0 8px 25px -8px rgba(130, 134, 139, 0.37) !important;
  background-color: transparent !important;
  border: solid 1px rgba(98, 98, 98, 0.33) !important;
  color: #1c1c1c !important;
  font-size: 12px;
}

.btn-cuponPop:focus {
  box-shadow: 0 8px 25px -8px rgba(130, 134, 139, 0.57) !important;
  background-color: transparent !important;
  border: solid 1px rgba(98, 98, 98, 0.33) !important;
  color: #1c1c1c !important;
  font-size: 12px;
}

.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #18237e !important;
}

.dimiInnertd {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 12px;
  text-transform: lowercase;
}

.newInputG {
  padding: 0.471rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.cardHomeEvents {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 65px;
  background-color: #000000ab !important;
  border-top: 10px solid #000000ab !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 15px 15px 0 0 !important;
}

// cardHomeEvents > div > h5
.cardHomeEvents > div {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin: 5px;
}

.dark-layout .bs-stepper {
  background: transparent !important;
}

.card-info-resume {
  margin-bottom: 20px;
  background: linear-gradient(
    144deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.61)
  );
  padding: 20px;
  border-radius: 6px;
  border: 1px solid rgba(44, 49, 65, 0.08);
}

.card-info-nomg {
  margin-bottom: 0px;
  background: linear-gradient(
    144deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.61)
  );
  padding: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid rgba(44, 49, 65, 0.08);
}

.card-info {
  margin-bottom: 20px;
  min-height: auto;
  background: linear-gradient(
    144deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.61)
  );
  padding: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 20px;
  border: 1px solid rgba(44, 49, 65, 0.08);
}

.map-image {
  width: 100%; /* A imagem ocupa 100% da largura do container pai */
  height: 150px; /* Altura fixa */
  object-fit: cover; /* Mantém proporções e corta o excesso */
}

.card-info-nomg-home1 {
  margin-bottom: 0px;
  background: linear-gradient(
    144deg,
    rgba(39, 46, 81, 0.2),
    rgba(46, 54, 70, 0.61)
  );
  padding: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid rgba(44, 49, 65, 0.08);
}

card-display {
  display: flex;
  justify-content: space-between;
}

.card-custom {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  background: linear-gradient(144deg, rgba(23, 27, 46, 1), rgba(42, 49, 67, 1));
  padding: 10px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  min-width: 400px;
}

.styled-table thead tr {
  background-color: #181c2f;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #181c2f;
}

.styled-table tbody tr:nth-of-type(odd) {
  background-color: #272e40;
}

.styled-table tbody tr:hover {
  background-color: #757575;
}

.lot-container {
  margin-bottom: 20px;
}

.lot-header {
  cursor: pointer;
  padding: 10px;
  background-color: #0c0c0c2d;
  border: 1px solid #dddddd;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lot-title {
  margin: 0;
  font-size: 1.2em;
  font-weight: bold;
}

.lot-summary {
  display: flex;
  gap: 20px;
}

.lot-item {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  color: #0149ff;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-menu.show {
  color: #6d0d3f;
  display: block;
}

.dropdown-item {
  min-width: 191.35px;
}

.dropdown-item:hover {
  background-color: #0056b3; /* Cor de fundo ao passar o mouse */
  color: white; /* Cor do texto ao passar o mouse */
}

.card-info-home1 {
  margin-bottom: 20px;
  background: transparent;
  padding: 20px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 20px;
  border: 3px solid rgba(255, 255, 255, 0.02);
  transition: 0.5s;
}
.card-info-home1:hover {
  box-shadow: 0px 0px 10px 0px rgba(46, 54, 70, 0.13);
  transition: 0.5s;
}

.shadow-border {
  box-shadow: 0px 0px 10px #0000000d !important;
  border-radius: 20px !important;
}

.go4108038823 {
  background: #ffffff !important;
  color: #353b48 !important;
  margin-top: 7px !important;
  transition: 0.5s;
}
.go4108038823::before {
  top: 50%;
  left: 0%;
  content: "";
  position: absolute;
  height: calc(100% - 4px);
  width: calc(33.33333333333333% - 4px);
  border-radius: 18px;
  border: 2px solid rgba(53, 59, 72, 0.57);
  background: #0097e6;
  transition: left 0.3s linear, background 0.3s linear;
  transform: translateY(-50%);
  z-index: 1;
  box-sizing: content-box;
  color: #fff !important;
}
.go4226752262 {
  background: #ffffff !important;
  color: #353b48 !important;
  margin-top: 7px !important;
  transition: 0.5s;
}

.go4226752262::before {
  top: 50%;
  left: 33.33333333333333%;
  content: "";
  position: absolute;
  height: calc(100% - 4px);
  width: calc(33.33333333333333% - 4px);
  border-radius: 18px;
  border: 2px solid rgba(53, 59, 72, 0.57);
  background: #259cd9;
  transition: left 0.3s linear, background 0.3s linear;
  transform: translateY(-50%);
  z-index: 1;
  box-sizing: content-box;
}
.go1065732830 {
  background: #ffffff !important;
  color: #353b48 !important;
  margin-top: 7px !important;
}
.go1065732830::before {
  top: 50%;
  left: 66.66666666666666%;
  content: "";
  position: absolute;
  height: calc(100% - 4px);
  width: calc(33.33333333333333% - 4px);
  border-radius: 18px;
  border: 2px solid rgba(53, 59, 72, 0.57);
  background: #259cd9;
  transition: left 0.3s linear, background 0.3s linear;
  transform: translateY(-50%);
  z-index: 1;
  box-sizing: content-box;
}

@media (max-width: 600px) {
  .card-info-nomg-home1 {
    padding: 40px 20px 40px 20px !important;
    display: flex;
    align-items: center;
    margin: 10px 10px 25px 10px !important;
    justify-content: center;
  }

  .card-info-home1 {
    padding: 40px 20px 40px 20px !important;
    display: flex;
    align-items: center;
    margin: 10px 10px 25px 10px !important;
    justify-content: center;
  }
}

.tab-ex-sel {
  border-bottom: 1px #7367f0 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 10px 10px 0 0;
}

.tab-ex {
  border-bottom: none !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-radius: 10px 10px 0 0;
}

.slick-dots {
  margin-bottom: 17px !important;
  text-align: center;
}

.ficon {
  color: black !important;
}

.card-info-date-selected {
  text-align: center;
  background: linear-gradient(
    144deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.61)
  );
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: none;
}

.spacemenu {
  border-bottom: solid !important;
  border-width: 1px !important;
  font-size: 12px !important;
  font-weight: 400;
}

.menu-dark .spacemenu {
  border-color: #1e253a !important;
}

.menu-light .spacemenu {
  border-color: #bdc5df !important;
}
.menu-title {
  font-size: 12px !important;
}
.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
.main-menu.menu-light .navigation > li.sidebar-group-active > a {
  color: #ffffff;
}

.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a,
[dir] .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  background: rgb(3, 141, 206) !important;
  border-radius: 0 !important;
}

@media (min-width: 600px) {
  .oculta-desk {
    display: none !important;
  }
}

.react-flow__renderer {
  z-index: 1;
}

.bg-altered {
  background-color: transparent !important;
}

.bg-altered:hover {
  //background-color: #27314d !important;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

/* Adicione esses estilos ao seu arquivo CSS global */
.table-responsive {
  margin-top: 10px;
  overflow-x: auto;
}

.gender-select__control,
.batch-select__control,
.ticket-select__control {
  background-color: var(--bg-secondary);
  border-color: var(--border-color);
  transition: border-color 0.2s;
  position: relative;

  &:hover {
    border-color: var(--border-hover-color);
  }
}

.cIgxln {
  width: 100%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent !important;
}

.chgTOl {
  width: 100%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent !important;
}

.name-label-1 {
  font-weight: 400;
  font-size: 12px;
}

.name-value-1 {
  font-weight: 400;
  font-size: 14px;
}

.name-label {
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 12px;
}

@media (max-width: 600px) {
  .name-label {
    margin-top: 5px !important;
    padding-left: 2px;
    margin-bottom: 10px !important;
  }
}

select {
  font-weight: 400;
  font-size: 12px !important;
}

.info-lb-new-edit-d {
  border-radius: 5px 3px 3px 0;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #ffffff;
  color: rgb(40, 38, 38);
  border: none;
  margin-top: -30px !important;
}

.info-lb-new-edit-p {
  border-radius: 5px 3px 3px 0;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #ffffff;
  color: rgb(40, 38, 38);
  border: none;
  margin-top: -14px !important;
}

.rotate {
  transition: 0.2s ease-in;
  transform: rotate(-180deg) !important;
}

.no-rotate {
  transition: 0.2s ease-in;
  transform: rotate(0deg);
}

.btn-round {
  border-radius: 50px !important;
}

.arrow-cs {
  background-color: red !important;
}

.info-lb-new-edit {
  margin-left: -1px !important;
  border-radius: 5px 3px 3px 0;
  font-weight: 400;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgba(255, 255, 255, 0.84);
  border: none;
  background-color: #332f5b;
  margin-top: -10px !important;
}

.btn-copy {
  border: solid 1px #3b4153 !important;
}

.ex-ico {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 2px;
  height: 15px;
  width: 15px;
  background-color: #ef4b4b;
}

.up-inline {
  position: absolute;
  margin-top: -10px;
  margin-left: -13px;
}

.info-lb-new {
  border-radius: 3px;
  font-weight: 400;
  font-size: 13px;
  border: solid 1px #3b4153;
  background-color: #20283d;
  margin-top: -30px !important;
}

input {
  font-weight: 400;
  font-size: 12px !important;
}

.border-title {
  border-left: solid 2px rgba(40, 48, 70, 0.4);
  padding-left: 10px;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
  background: transparent;
  color: rgba(32, 40, 61, 0.9);
  border-color: rgba(32, 40, 61, 0.15);
  transition: 0.5s;
  box-shadow: none;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  margin-left: -1px;
  background: transparent;
  color: rgba(32, 40, 61, 0.9);
  border-color: rgba(32, 40, 61, 0.15);
  transition: 0.5s;
  box-shadow: none;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: rgba(32, 40, 61, 0.15);
  color: rgba(32, 40, 61, 0.9) !important;
  transition: 0.5s;
  border-color: rgba(32, 40, 61, 0.15) !important;
  box-shadow: none;
}
.fc .fc-button-primary {
  background: transparent;
  color: rgba(32, 40, 61, 0.9) !important;
  border-color: rgba(32, 40, 61, 0.15);
  transition: 0.5s;
  box-shadow: none;
}
.fc .fc-button-primary:hover {
  background: rgba(32, 40, 61, 0.2);
  color: rgba(32, 40, 61, 0.9) !important;
  border-color: rgba(32, 40, 61, 0.15);
  transition: 0.5s;
  box-shadow: none;
}
.fc .fc-button-primary:disabled {
  background: transparent;
  color: rgba(32, 40, 61, 0.9) !important;
  border-color: rgba(32, 40, 61, 0.4) !important;
  transition: 0.5s;
  box-shadow: none;
}
.fc .fc-button:focus {
  box-shadow: none !important;
  outline: 0;
}

.card-scroll {
  overflow-x: hidden;
  height: 870px !important;
  overflow-y: scroll;
}

.card-mobile {
  min-height: 100%;
}
.card-mobile2 {
  min-height: 100%;
  border-right: none;
}

.react-flow__attribution {
  display: none !important;
}

.div-posi-expand {
  z-index: 1;
  margin-top: 120px;
  position: absolute;
}

@media (max-width: 600px) {
  .fc .fc-toolbar-title {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .fc .fc-toolbar {
    align-items: center;
    display: block !important;
    justify-content: space-between;
  }
  .oculta-mobile {
    display: none !important;
  }

  .react-flow__node-default {
    border: 1px solid #0c7095eb !important;
  }

  .card-mobile {
    min-height: 130px;
  }
  .card-mobile2 {
    min-height: 100%;
    border-right: solid 1px !important;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.spacemenutop {
  border-top: solid !important;
  border-width: 1px !important;
  border-color: #1e253a !important;
}

.main-menu.menu-light .navigation > li > a {
  padding-left: 30px;
}

.main-menu.menu-light .navigation > li > a {
  padding-bottom: 13px;
  padding-top: 13px;
}

.main-menu ul.navigation-main > li:first-child {
  margin-top: 0 !important;
}

.main-menu.menu-light .navigation > li > a {
  margin: 0 0 !important;
}

.card-info-date {
  color: #020202;
  cursor: pointer;
  text-align: center;
  background: linear-gradient(
    144deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.61)
  );
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid rgba(44, 49, 65, 0.08);
}

.date-filter {
  margin-top: 10px !important;
  font-size: 12px;
  font-weight: 500;
}

.card-info-date-selected {
  background: #27d546;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  border-radius: 10px;
  color: white !important;
  border: 1px solid rgba(44, 49, 65, 0.08);
}

.card-info-date:hover {
  background: #e1e1e1;
}

.date-filter-txt {
  margin-top: 4px !important;
  margin-bottom: 2px !important;
  font-size: 11px;
  font-weight: 400;
}
.dark-layout .border,
[dir] .dark-layout .border-top,
[dir] .dark-layout .border-end,
[dir] .dark-layout .border-bottom,
[dir] .dark-layout .border-start {
  border-color: #3b4253 !important;
}

.rdw-editor-toolbar {
  border-radius: 12px !important;
}

[dir] .dark-layout .modal .modal-header,
[dir] .dark-layout .modal .modal-header[class*="bg-"] {
  background-color: #444444;
}

.dark-layout
  .react-dataTable
  .rdt_Table
  > div:first-child:not(.rdt_TableHead):not(.rdt_TableBody) {
  background-color: transparent !important;
}

.color-icon {
  color: #484848;
}

.ijcpKa {
  width: 100%;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
}

.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0;
}

.card-info-3 {
  background: linear-gradient(
    144deg,
    rgba(180, 182, 190, 0.2),
    rgba(166, 170, 178, 0.61)
  );
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.06) !important;
}

.card-info-2 {
  background: linear-gradient(
    144deg,
    rgba(39, 46, 81, 0.2),
    rgba(239, 239, 239, 0.61)
  );
  padding: 10px;
  border-radius: 30px;
  border: 1px solid rgba(44, 49, 65, 0.16);
}

.newForm {
  background-color: transparent !important;
}

.css-1pahdxg-control {
  color: #2a2a2a !important;
  border: solid 1px rgba(107, 104, 104, 0.38);
  background-color: transparent !important;
}

.css-1s2u09g-control {
  height: 40px !important;
  color: #282828 !important;
  border: solid 1px rgba(107, 104, 104, 0.38);
  background-color: transparent !important;
}

.css-1pndypt-Input {
  color: #383838 !important;
}

.css-qc6sy-singleValue {
  color: #262626 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.swal2-confirm.swal2-styled {
  margin-right: 10px;
}

.cor-icon {
  color: #2c2c2c !important;
}

.label-input {
  margin-bottom: 5px !important;
}

.sold-cap {
  margin-top: 2px;
  font-size: 12px;
}

.sold-style {
  font-size: 18px;
  font-weight: 600;
}

.ticket-style {
  margin-bottom: 20px;
  border: solid 1px rgba(224, 224, 224, 0.59);
  padding: 20px;
  background-color: #f8f8f8;
}

.bg-delete {
  cursor: pointer;
  border: solid 1px rgba(255, 48, 48, 0.59);
  border-radius: 20px;
  width: 40px;
  text-align: center;
  padding: 8px;
  height: 40px;
}

.bg-delete:hover {
  background-color: #ffd2d2;
}

.bg-not-delete {
  cursor: no-drop;
  border: solid 1px rgba(255, 161, 161, 0.59);
  border-radius: 20px;
  width: 40px;
  text-align: center;
  padding: 8px;
  height: 40px;
}

.config-next-ticket {
  background-color: #fafafa !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.config-atual {
  font-size: 16px;
}

@media (max-width: 600px) {
  .config-atual {
    font-size: 13px;
  }
}

.config-atual-proximo {
  font-size: 14px;
}

.config-next-ticket:focus {
  background-color: #fafafa !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.config-next-ticket:active {
  background-color: #fafafa !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.btn-export-model {
  background-color: white !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.btn-export-model:focus {
  background-color: white !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.btn-export-model:active {
  background-color: white !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.btn-export-model:hover {
  box-shadow: 0 8px 25px -8px #b2b2b2 !important;
}

.demo-editor {
  padding: 0 20px 20px 20px;
  min-height: 125px !important;
}

.balao-text {
  width: 200px;
  background-color: #363638;
  color: white;
  padding: 10px;
  font-size: 10px;
  border-radius: 10px;
}

.define-div {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3%;
  right: 3%;
  height: 62%;
  margin-top: 8%;
}

[dir] .bs-stepper .bs-stepper-content {
  padding: 0 !important;
}
h5 {
  margin: 0 !important;
}
.oculta {
  filter: blur(4px);
}

.swal2-input {
  width: 100% !important;
  font-size: 13px !important;
}

.tabela-card {
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.btn-ev {
  padding-top: 5px !important;
  font-size: 10px;
  padding-bottom: 5px !important;
  background-color: transparent !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.btn-ev:active {
  padding-top: 5px !important;
  font-size: 10px;
  padding-bottom: 5px !important;
  background-color: transparent !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}
.btn-ev:focus {
  padding-top: 5px !important;
  font-size: 10px;
  padding-bottom: 5px !important;
  background-color: transparent !important;
  border: solid 1px rgba(224, 224, 224, 0.59) !important;
  color: black !important;
}

.text-graph {
  color: black;
  font-size: 12px !important;
}

.text-info-data {
  color: #2a2a2a;
  font-size: 12px !important;
}

.titulo-ev {
  cursor: pointer;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #1b2337;
  font-family: Poppins, sans-serif !important;
}

.desc-ev {
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
  font-weight: 500;
}
.card-inner {
  border-radius: 5px;
  margin-top: 5px;
  border: solid 1px rgba(224, 224, 224, 0.11) !important;
  padding: 10px;
}

.ticket-ex {
  font-size: 13px !important;
  font-weight: 400;
}

.card-text {
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
  font-weight: 500;
}

.desc-info {
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
}

.card-member {
  border-radius: 5px;
  margin-top: 5px;
  border: solid 1px rgba(224, 224, 224, 0.11) !important;
  padding: 10px;
}

.desc-info-2 {
  font-family: Poppins, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
  color: black;
}

.titulo-up {
  font-family: Poppins, sans-serif !important;
  font-size: 15px !important;
  margin-bottom: 7px !important;
  font-weight: 400 !important;
}

.icone-edit {
  position: absolute;
  margin-top: 12px;
  right: 44%;
}

@media (max-width: 768px) {
  .icone-edit {
    right: 14% !important;
  }
}

.icone-top:hover {
  transition: 0.5s;
  cursor: pointer;
  color: #1b2337 !important;
}

.value-data {
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #1b2337;
  font-family: Poppins, sans-serif !important;
}

.status-ev {
  font-family: Poppins, sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}

.desc-info-3 {
  font-family: Poppins, sans-serif !important;
  font-size: 15px !important;
}

.input-edit {
  margin-top: 2px;
  height: 50px !important;
}

.select__control {
  margin-top: 2px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.input-edit:focus {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de !important;
  border-radius: 0.357rem;
  box-shadow: none !important;
}

.boxLivre {
  margin-top: 5px !important;
  height: 35px;
  padding: 5px;
  cursor: pointer;
  font-size: 17px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: center;
  color: white;
  width: 35px;
  border-radius: 6px;
  background-color: #6bb46b;
}

.bg-color-l {
  background-color: #20b620;
}

.box18 {
  margin-top: 5px !important;
  height: 35px;
  cursor: pointer;
  padding: 5px;
  font-size: 17px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  text-align: center;
  padding-left: 7px !important;
  color: white;
  width: 35px;
  border-radius: 6px;
  background-color: #5e5e5e;
}

.bg-color-18 {
  background-color: black;
}

@media (min-width: 600px) {
  .cardEvInfo {
    padding: 20px;
    border: solid 1px rgba(224, 224, 224, 0.59) !important;
  }
}

//reserve
.locale-content-all {
  min-width: 30px !important;
  font-weight: 400 !important;
  border-radius: 3px !important;
  font-size: 11px !important;
}

.locale-content-all-status {
  min-width: 30px !important;
  font-weight: 400 !important;
  border: none !important;
  border-radius: 3px !important;
  font-size: 11px !important;
}

.locale-content {
  cursor: pointer;
  font-weight: 400 !important;
  border-radius: 3px !important;
  border: solid 1px rgba(255, 255, 255, 0.16) !important;
  background-color: transparent !important;
}

.fc-col-header-cell-cushion {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  color: black;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
}

.card-member-list {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px;
  border: solid 1px rgba(224, 224, 224, 0.62) !important;
  padding: 10px;
}

.card-member-list:hover {
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px;
  background-color: rgba(61, 61, 61, 0.07) !important;
  border: solid 1px rgba(224, 224, 224, 0.62) !important;
  padding: 10px;
}

.text-member {
  color: #1e1e1e;
  font-size: 13px;
}

.text-member-role {
  font-size: 11px;
  font-weight: 400;
  color: #a0aac9;
}

.title-ev-date {
  font-size: 10px;
  font-weight: 400;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin-left: 2px;
  margin-bottom: 13px;
}

.fc-list-event-graphic {
  display: none !important;
}

.close-icon {
  color: #000000 !important;
}

.close-icon:hover {
  color: rgba(0, 0, 0, 0.84) !important;
}

.title-ev-time {
  font-size: 11px;
  color: #ced2e1;
  font-weight: 400;
}

.fc-h-event {
  border: none !important;
}

.wrap-custom {
  white-space: pre-wrap !important;
}

.fc.fc-daygrid-event {
  white-space: pre-wrap;
}

.vh-col-custom {
  min-height: 94vh !important;
}

.label-reserve {
  font-size: 11px !important;
  font-weight: 400;
}

.fc-header-toolbar {
  padding: 30px;
}

.name-label-reserve {
  font-size: 13px !important;
  font-weight: 400;
}

.fc-list-day-cushion.fc-cell-shaded {
  margin-top: -2px !important;
}

.bg-h-op:hover {
  background-color: #f1f1f1 !important;
}

.btn-list-op {
  border: solid 1px rgba(69, 85, 107, 0.38) !important;
}

.event-holiday-title {
  font-size: 13px !important;
  font-weight: 400;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  min-height: 900px !important;
}

.btn-status {
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: white;
  font-size: 13px;
}

.table {
  --bs-table-striped-bg: #2b374d !important;
  --bs-table-hover-bg: #222a3d;
}

.txt-small-tb {
  font-size: 11px !important;
  font-weight: 400;
}

.estilo-tb-sm {
  text-transform: capitalize;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.info-lb-new-edit-d {
  border-radius: 5px 3px 3px 0;
  border: solid 1px #f6f6f6;
}
.table {
  --bs-table-striped-bg: #ffffff !important;
  --bs-table-hover-bg: #eeeeee;
}
.btn-list-op:hover {
  background-color: rgba(66, 66, 86, 0.2);
}

.name-label-small {
  font-size: 11px !important;
  font-weight: 400;
}

.value-data-small {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #1b2337;
  font-family: Poppins, sans-serif !important;
}

// custom css test
.change-data {
  background-color: #fff;
  border: 1px double #eee;
  color: rgb(41, 146, 208);
  padding: 20px 10px 25px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 3px #999999;
}
.myCustomPrefix__value-container > div {
  width: auto !important;
}

// data-tah="allowRowEvents" set width to 100%
#cell-5-undefined > div {
  width: 100% !important;
}

.notion-title {
  font-size: 2.5em;
  color: white !important;
  font-weight: 700;
  margin-top: 0.75em;
  margin-bottom: 0.25em;
}

.notion {
  color: white !important;
}

.svgFunnelLabel-0-0-79 {
  background-size: 2px 14px;
  background-image: linear-gradient(
    #6a81b987 40%,
    rgba(95, 95, 95, 0) 0%
  ) !important;
  background-repeat: repeat-y;
  background-position: left;
}

@keyframes test-animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.test-animate {
  background-color: #ce300c;
  -webkit-animation: test-animate 0.9s ease-in-out infinite;
  -moz-animation: test-animate 0.9s ease-in-out infinite;
  -ms-animation: test-animate 0.9s ease-in-out infinite;
  -o-animation: test-animate 0.9s ease-in-out infinite;
  animation: test-animate 0.9s ease-in-out infinite;
}

.button-excluir {
  background-color: #ff3d3d;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-glass {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  bottom: 45px;
  right: 28px;
  height: 40px;
  border-radius: 10px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 0 5px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}

.extraDiv {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 10px;
  transform: translateY(50%);
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  border-radius: 15px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
}

.extraDiv.visible {
  transform: translateY(0%);
  opacity: 1;
}

.background-not {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.11);
}

.title-not {
  font-size: 13px;
  font-weight: 400;
}

.date-not {
  font-size: 11px;
  font-weight: 400;
}

.card-not {
  border: solid 2px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.collapsible-hidden {
  max-height: 0;
}

.collapsible-shown {
  max-height: 500px;
}

.button-not {
  background-color: #00b914;
  border: solid 1px rgba(0, 0, 0, 0.11);
  border-radius: 80px;
  width: 45px;
  height: 45px;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  margin: 25px 0 0 10px;
}

.button-not2 {
  background-color: #0596d7;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  margin-top: 15px;
}

.maquininha {
  background-image: url("../images/maquininha.png");
  background-size: contain;
  background-position: right;
  height: 700px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  width: 270px;
  padding: 30px;
}

.category-bg-maq {
  background-color: #0596d7;
  border-radius: 100px;
  padding: 3px 10px;
  color: #ffffff;
  font-size: 7px;
  font-weight: 400;
  margin: -5px 5px 0 3px;
}

.scroll-category {
  overflow-x: scroll;
  max-width: 190px;
  user-select: none;
}

.scroll-category::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.input-produtos {
  background-color: transparent;
  border: none;
  padding: 2px 2px 2px 8px;
  font-size: 9px;
  outline: none;
}

.input-produtos::placeholder {
  font-size: 7.5px;
}

.search-button {
  background-color: #0596d7;
  border: none;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.card-prod {
  background-color: #ffffff;
  border-radius: 7px;
  border: solid 1px rgba(0, 0, 0, 0.11);
  padding: 10px 8px 8px 8px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 30%;
  text-align: center;
}

.card-scroll-maq {
  overflow-y: scroll;
  max-height: 180px;
  margin-bottom: -40px;
}

// scroll

.card-scroll-maq::-webkit-scrollbar {
  width: 8px;
  background: #fff;
  border-radius: 10px;
}

.card-scroll-maq::-webkit-scrollbar-thumb {
  background: rgba(159, 159, 159, 0.64);
  border-radius: 10px;
  border: 2px #ffffff solid;
}

// scroll

.fc .fc-scroller-liquid::-webkit-scrollbar {
  width: 14px;
  background: #fff;
  border-radius: 10px;
}

.fc .fc-scroller-liquid::-webkit-scrollbar-thumb {
  background: rgb(159, 159, 159);
  border-radius: 10px;
  height: 200px;
  border: 2px #ffffff solid;
}

.cardDisp {
  border-radius: 15px;
  background-color: #00000010;
  padding: 18px;
  width: 90%;
  border: solid 2px rgba(255, 255, 255, 0.2);
  position: relative;
}

.cardDispInfo {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border: solid 2px rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px #1a1a1a66;
  justify-content: space-between;
  margin-top: 10px;
}

.cardDispInfo2 {
  border-radius: 10px;
  padding: 10px;
  border: solid 2px rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  // morphism
  background: #0000001f;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.buttonDisp {
  background-color: #0596d7;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
  padding: 7px;
}

.cardCategoriaDisp {
  border-radius: 10px;
  background-color: #161d31;
  padding: 15px;
  border: solid 2px rgba(255, 255, 255, 0.1);
}

.cardDetails {
  border-radius: 15px;
  background-color: #161d31;
  padding: 18px;
  width: 90%;
  border: solid 2px rgba(255, 255, 255, 0.2);
  position: relative;
}

.cardDispProds {
  border-radius: 15px;
  background-color: #161d31;
  padding: 18px;
  width: 100%;
  position: relative;
}

.cardBodyDisp {
  background-color: rgba(255, 255, 255, 0.06);
  padding: 2px 10px 2px 10px;
  border-radius: 8px;
  margin-top: 10px;
}

.buttonLeftArrow {
  background-color: rgba(5, 150, 215, 0);
  border: solid 2px rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: 12px;
}

.title-response {
  font-size: 19px;
  font-weight: 400;
}
.titulo-event-info-response {
  font-size: 18px;
  font-weight: 500;
}
.text-infos-card-response {
  font-size: 14px !important;
  font-weight: 400;
}

.padding-response-card {
  padding: 5px 0 5px 0;
}

.icon-map-info-card-response {
  width: 16px;
  height: 16px;
}

.text-status-card-info-response {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .event-component .d-flex {
    flex-direction: column;
  }

  .title-response {
    font-size: 15px;
    font-weight: 400;
  }

  .text-infos-card-response {
    font-size: 10px !important;
    font-weight: 400;
  }

  .titulo-event-info-response {
    font-size: 14px;
    font-weight: 500;
  }

  .icon-map-info-card-response {
    width: 38px;
    height: 38px;
  }

  .text-status-card-info-response {
    font-size: 12px;
    font-weight: 400;
  }

  .btn-back-2 {
    height: 35px !important;
    background-color: transparent !important;
    border: solid 1px rgba(224, 224, 224, 0.59) !important;
    color: #2c2c2c !important;
    font-size: 10px !important;
  }

  .btn-cuponPop {
    border: solid 1px rgba(98, 98, 98, 0.33) !important;
    color: #1c1c1c !important;
    background-color: transparent !important;
    font-size: 10px !important;
  }
}

.fundo-modal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

/// ticket

.button-close-modal {
  background-color: #ff5454;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
}

.background-modal {
  background-color: rgba(255, 255, 255, 0.88);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-ticket {
  background-color: #ffffff;
  color: #000;
  border: solid 1px rgba(0, 0, 0, 0.11);
  margin-top: 10px;
  margin-bottom: 5px;
}

.info-ticket {
  font-size: 10px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  padding-left: 10px;
  margin-top: 5px;
}
.item {
  position: relative;
  width: 100%;
}
.lote-ticket {
  font-size: 10px;
  font-weight: 400;
  background-color: rgba(241, 241, 241, 0.7);
  border-top: solid 1px rgba(0, 0, 0, 0.09);
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.info-gender {
  border-right: solid 1px rgba(0, 0, 0, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  padding: 0 10px 0 10px;
  margin-right: 8px;
}

.ticket {
  font-size: 12px;
  height: 55px;
  font-weight: 400;
  background-color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.info-name-ticket {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}

.info-value-ticket {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}

.quant-ticket {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 180px;
  padding-right: 15px;
}

.quant-ticket-icon-1 {
  font-size: 22px;
  border-radius: 100px;
  border: solid 2px rgba(255, 100, 100, 0.55);
  color: rgba(255, 100, 100, 0.55);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-quant-ticket {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #f1f1f1e8;
  padding: 8px 13px;
  border-radius: 8px;
}

.quant-ticket-icon-2 {
  font-size: 20px;
  border-radius: 100px;
  border: solid 2px rgba(0, 155, 22, 0.55);
  color: rgba(0, 155, 22, 0.55);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item.dragging {
  cursor: grabbing;
  position: absolute;
}

.droppable-container {
  position: relative;
  overflow: hidden;
}

.input-search-style {
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.18);
  padding: 5px 5px 5px 15px;
  outline: none;
  border-radius: 100px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
}

.input-search {
  background-color: transparent;
  border: none;
  font-size: 13px;
  outline: none;
  color: #ffffff;
  width: 85%;
}

.button-search {
  background-color: #0596d7;
  border: none;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
  width: 42px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-max-index {
  z-index: 99999;
  width: 5000px !important;
}

// personalizar scroll

.scroll-modal-portaria::-webkit-scrollbar {
  width: 8px !important;
  background: #fff !important;
  border-radius: 10px !important;
}

.scroll-modal-portaria::-webkit-scrollbar-thumb {
  background: rgb(159, 159, 159) !important;
  border-radius: 10px !important;
  height: 200px !important;
  border: 2px #ffffff solid !important;
}

.scroll-modal-portaria::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 10px !important;
}

.scroll-modal-portaria::-webkit-scrollbar-track:hover {
  background: #fff !important;
  border-radius: 10px !important;
}

@media (max-width: 600px) {
  .input-search-style {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .input-search {
  }

  .button-search {
  }
}


// scrollbar style

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  background: #7e7e7e;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #515152;
}

